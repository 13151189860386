import {apiEnvironment} from '../smoothr-web-app-core/environments/apiEnvironment';

export const olearysCustomerGroup = 'olearys';
export const customerGroup = olearysCustomerGroup;

const supportEmail = {
	olearys: 'olearys@smoothr.de'
};
const firebaseConfig = {
	olearys: {
		apiKey: 'AIzaSyBifkZtJiF4WzB0buDoVWTdUE9rF4RxXz0',
		authDomain: 'olearys-f5ef1.firebaseapp.com',
		projectId: 'olearys-f5ef1',
		storageBucket: 'olearys-f5ef1.appspot.com',
		messagingSenderId: '327368733552',
		appId: '1:327368733552:web:65e99d3a4b8f04958395b5'
	}
};
export const environment = {
	customerGroup,
	webAppUrl: window.location.protocol + '//' + window.location.host,
	placesApiKey: 'AIzaSyBv5IfnRegn5x6r9X6mSliaZfgx99jyI6U',
	SUPPORT_EMAIL: supportEmail[customerGroup],
	firebaseConfig: firebaseConfig[customerGroup],
	APAY_MERCHANT_ID: 'merchant.smoothr.' + customerGroup + '-dev',
	...apiEnvironment,
	countryList: ['es'],
	hasRecommendations: true,
	showOrderPriceFromBackend: false,
	showDiffrenceModal: false,
	payPalValidatePrice: false,
	payPalMinPrice: 0
};

import 'zone.js/dist/zone-error';
